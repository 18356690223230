<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_receivables')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card no-body>

            <b-card-body class="pb-0">

                <b-row>

                    <b-col cols="12">
                        <b-row class="mb-1">

                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_type_of_ageement')}}</label>
                                <v-select

                                        class="sort--select form-control"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        v-model="filterData.agreement_type_id"
                                        :options="agreementTypes"
                                        label="name"
                                        :placeholder="$t('label_type_of_ageement')"
                                        :reduce="val => val.id"

                                >
                                    <template v-slot:option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span class="">{{ option.name }}</span>
                                    </template>
                                    <template v-slot:selected-option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span class="badge badge-light-success">{{ option.name}}</span>
                                    </template>

                                </v-select>
                            </b-col>
                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_deal_number')}}</label>
                                <!--:query-params="{participant_type:'recipient'}"-->
                                <infinity-scroll
                                        v-model="filterData.agreement_id"
                                        :multiple="false"
                                        primary-key="deal_id"
                                        url="/deal"
                                        selectedProp="agreement_id"
                                        :placeholder="$t('label_deal_number')"

                                >
                                    <template #label="{item}">{{ item.deal_number }}</template>

                                </infinity-scroll>
                            </b-col>
                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_payers_name_and_surname')}}</label>
                                <!--:query-params="{participant_type:'recipient'}"-->
                                <infinity-scroll
                                        v-model="filterData.contact_id"
                                        :multiple="false"
                                        primary-key="id"
                                        url="/contact"
                                        selectedProp="id"
                                        :placeholder="$t('label_payers_name_and_surname')"

                                >
                                    <template #label="{item}">{{ item.first_name + ' ' + item.last_name  }}</template>

                                </infinity-scroll>
                            </b-col>
                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_status_of_payment')}}</label>
                                <v-select
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        v-model="filterData.pay_statuses"
                                        :options="paymentStatuses"
                                        :multiple="true"
                                        class="w-100"
                                        :placeholder="$t('label_select')"
                                        :reduce="val => val.alias"
                                >
                                    <template v-slot:option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span class="">{{ $t(option.label)}}</span>
                                    </template>
                                    <template v-slot:selected-option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span class="">{{$t(option.label)}}</span>
                                    </template>
                                </v-select>

                            </b-col>
                            <b-col cols="12" md="3" class="mb-1">
                                <!--<label>{{$t('label_show_unpaid_payments_on_time')}}</label>-->
                                <b-form-checkbox
                                        v-model="filterData.overdue"

                                        value="1"
                                        unchecked-value="0"
                                >
                                    <!--class="mt-1"-->
                                    {{$t('label_show_unpaid_payments_on_time')}}
                                </b-form-checkbox>
                            </b-col>

                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <b-card
                no-body
                class="mb-0"
        >

            <b-col
                    cols="12"
                    md="12"
                    class=" mb-1 mt-1 position-relative"
            >


                <div class="pb-2 mt-1 table-top-toolbar" v-if="tableItems.length > 0">


                            <b-row   >

                                <b-col
                                        cols="12"
                                        md="6"
                                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                                >

                                    <v-select
                                            v-model="tableData.perPage"
                                            :searchable="false"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="tableData.perPageOptions"
                                            :clearable="false"
                                            class="per-page-selector d-inline-block mx-50"
                                    />
                                    <!--<column-chooser v-model="selectedColumns" :not-touch="['id']"  :columns="columnsDefs"></column-chooser>-->

                                </b-col>


                            </b-row>
                </div>

                <b-table-simple v-if="tableItems.length > 0" style="min-height: 146px" class="multiple-table" caption-top responsive no-border-collapse>

                   <b-thead>
                       <b-tr class="t-caption">
                           <b-th v-for="column in columnsDefs">
                               <span v-if="column.key == 'paid'">{{$t(column.label) + ' ' + $t('label_brutto')}}</span>
                               <span v-else-if="column.key == 'ide'" v-b-tooltip.hover.top="$t(column.label) + ' ' + $t('label_brutto')">{{$t(column.label) + ' ' + $t('label_brutto')}}</span>
                               <span v-else>{{$t(column.label)}}</span>
                           </b-th>
                       </b-tr>
                   </b-thead>
                    <b-tbody>
                        <template v-for="item in tableItems">

                            <b-tr :data-pk="item.id">
                                <b-td>
                                    <feather-icon
                                            :color="item.detailsShowed? 'danger' : '#7367f0'"
                                            @click="getDetails(item)"
                                            size="20"
                                            class="cursor-pointer"
                                            :icon="item.detailsShowed? 'MinusCircleIcon' : 'PlusCircleIcon'"
                                    />
                                </b-td>
                                <b-td>
                                    <router-link v-if="$can('view', 'agreement.tab_details')" :to="{name: 'agreement-details', params:{id:item.agreement_id}}">
                                        {{item.deal_number}}
                                    </router-link>
                                    <template v-else>
                                        {{item.deal_number}}
                                    </template>
                                </b-td>
                                <b-td>{{item.gross_amount? item.gross_amount.formatPrice() + ' ' + $t('label_pln_gross') : 0}}</b-td>
                                <b-td>{{formatDate(item.created_at, 'DD.MM.YYYY HH:mm', 'unix')}}</b-td>
                                <b-td>{{formatDate(item.deadline_at, 'DD.MM.YYYY HH:mm', 'unix')}}</b-td>
                                <b-td>
                                    <template v-if="item.documents.length > 0">
                                        <a v-for="doc in item.documents" target="_blank" :title="doc.display_name"
                                           class="preview-firstly" @click="previewFile($event, MODULE_PREFIX + '_payments/downloadPayDoc/', doc)" href="#">
                                            <feather-icon icon="FileIcon"></feather-icon>
                                        </a>
                                    </template>
                                    <span v-else>--</span>
                                </b-td>
                                <b-td>{{calcCommonNetSum(item.rows, 'gross_amount').formatPrice()+ ' ' + $t('label_pln_gross')}}</b-td>
                                <b-td>{{(parseFloat(item.gross_amount) - calcCommonNetSum(item.rows, 'gross_amount')).formatPrice()+ ' ' +
                                    $t('label_pln_gross')}}
                                </b-td>
                                <b-td v-if="$can('edit', 'agreement.tab_payments')">
                                    <b-dropdown
                                            variant="link"
                                            no-caret
                                            :right="$store.state.appConfig.isRTL"
                                    >

                                        <template #button-content>
                                            <feather-icon
                                                    icon="MoreVerticalIcon"
                                                    size="16"
                                                    class="align-middle text-body"
                                            />
                                        </template>

                                        <template v-if="$can('edit', 'agreement.tab_payments')">
                                            <b-dropdown-item
                                                    @click="payForDueLaw = item;$bvModal.show('client_payment-modal')">
                                                <feather-icon icon="AirplayIcon"/>
                                                <span class="align-middle ml-50">{{ $t('label_add_payment_from_client') }}</span>
                                            </b-dropdown-item>

                                            <b-dropdown-item @click="editedDueLaw = item;$bvModal.show('due_law-modal'); ">
                                                <feather-icon icon="EditIcon"/>
                                                <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
                                            </b-dropdown-item>

                                            <b-dropdown-item @click="removePayTable(item.id)">
                                                <feather-icon icon="TrashIcon"/>
                                                <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
                                            </b-dropdown-item>

                                        </template>
                                    </b-dropdown>
                                </b-td>

                            </b-tr>
                            <b-tr v-if="item.detailsShowed" class="row-details">
                                <b-td colspan="8">
                                    <b-card>
                                        <b-table-simple v-if="item.rows.length > 0" small caption-top no-border-collapse class="table-details"
                                                        style="padding-left:50px;width:100%">
                                            <b-thead head-variant="light">
                                                <b-tr>
                                                    <b-td>{{$t('label_deposit_amount') + ' ' + $t('label_brutto')}}</b-td>
                                                    <b-td>{{$t('label_date_of_payment_amount')}}</b-td>
                                                    <b-td>{{$t('label_document')}}</b-td>
                                                    <b-td>{{$t('label_action')}}</b-td>
                                                </b-tr>
                                            </b-thead>
                                            <b-tbody>

                                                <b-tr v-for="subRow in item.rows">
                                                    <b-td>{{subRow.gross_amount.formatPrice() + ' ' + $t('label_pln_gross') }}</b-td>
                                                    <b-td>{{formatDate(subRow.payment_date_at, "DD.MM.YYYY HH:mm",
                                                        'unix')}}
                                                    </b-td>
                                                    <b-td>
                                                        <template
                                                                v-if="subRow.file_id && $can('view.agreement.tab_document')">
                                                            {{subRow.display_name }}
                                                            <a target="_blank"
                                                               @click="previewFile($event, MODULE_PREFIX + '_payments/downloadPayDoc/', {id:subRow.file_id, hash:subRow.hash, dir:'uploads/due_law_docs/' , file_name:subRow.file_name})" href="#">
                                                                <feather-icon icon="FileIcon"/>
                                                            </a>
                                                        </template>
                                                        <span v-else>--</span>
                                                    </b-td>

                                                    <b-td>
                                                        <feather-icon v-if="$can('Edit', 'agreement.tab_payments')"
                                                                      @click="removePayRow(subRow.id)" icon="TrashIcon"
                                                                      class="cursor-pointer" color="red"/>
                                                    </b-td>
                                                </b-tr>

                                            </b-tbody>
                                        </b-table-simple>
                                        <span v-else>{{$t('label_no_items')}}</span>

                                    </b-card>
                                </b-td>
                            </b-tr>
                        </template>
                    </b-tbody>

                </b-table-simple>
                <span v-else>
                                  {{$t('label_no_records_available')}}
                              </span>
                <div class="mx-2 mb-5" v-if="tableItems.length > 0">
                    <b-row>

                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >

                            <b-pagination
                                    v-model="tableData.currentPage"
                                    :total-rows="tableTotal"
                                    :per-page="tableData.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>


            </b-col>
        </b-card>

        <client-payment-modal
                :module-item="false"
                :is-deal="false"
                :pay-for-due-law="payForDueLaw"
                :edited-item="editedClientPay"
                :receivables-options="false"
                @item-added="refreshDataTable();getCommonPaySumAndDate()"
        ></client-payment-modal>

        <due-law-modal
                :module-item="false"
                :edited-item="editedDueLaw"
                :is-deal="false"
                @item-added="refreshDataTable();getCommonPaySumAndDate(); updateRecivables()"
                @item-edited="refreshDataTable();getCommonPaySumAndDate()"
        ></due-law-modal>

    </div>
</template>

<script>

    import {
        BCard, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip,
        BTbody, BThead, BTh, BTr, BTableSimple, BTd, VBTooltip
    } from 'bootstrap-vue'

    import vSelect from 'vue-select'
    import columnChooser from '@/views/components/columnChooser'

    import infinityScroll from '@/views/components/infinityScroll'

    import dueLawModal from './../agreement/modals/processDueLawModal'
    import clientPaymentModal from './../agreement/modals/processClientPaymentModal'


    import {RECEIVABLES_PREFIX as PREFIX, AGREEMENT_PREFIX as MODULE_PREFIX, calcCommonNetSum} from './../agreement/moduleHelper'

    export default {
        components: {BCard, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
            BPagination, BCardBody, BFormCheckbox, BCardHeader, BTooltip, BTbody, BThead, BTh, BTr, BTableSimple, BTd,
            vSelect,
            columnChooser,
            dueLawModal,
            clientPaymentModal,
            infinityScroll,

        },
        props: ['tableType'],
        directives: {
            'b-tooltip': VBTooltip,
        },
        data() {
            return {
                MODULE_PREFIX,
                PREFIX,

                selectedColumns:[],

                editedClientPay: false,
                editedDueLaw: false,
                payForDueLaw: false,

                calcCommonNetSum,

                columnsDefs: [

                    {label: 'label_#', key: 'details_control', tdClass: 'details-control'},
                    {label: 'label_deal_number', key: 'deal_number', sortable: false},
                    {label: 'label_gross_amount', key: 'gross_amount'},
                    {
                        label: 'label_adding_date',
                        key: 'created_at',
                        sortable: false,
                        tdClass: 'rcv-name',
                        formatter(v) {
                            return formatDate(v, 'DD.MM.YYYY HH:mm', 'unix')
                        }
                    },
                    {label: 'label_date_of_payment', key: 'deadline_at', sortable: false},
                    {label: 'label_document', key: 'display_name', sortable: false},
                    {label: 'label_paid', key: 'paid', sortable: false},
                    {label: 'label_left_to_pay', key: 'ide', sortable: false},
                    {label: 'label_edit', key: 'actions'}

                ],

                isBusy: false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 100,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [8, 10, 25, 50, 100],
                },
                filterData: {
                    agreement_type_id: '',
                    agreement_id: '',
                    contact_id: '',
                    overdue:0,
                    pay_statuses:[]
                },

                agreementTypes: [],
                paymentStatuses: [{alias:'only_paid', label: 'label_paid'},{alias:'only_not_paid', label: 'label_not_paid'},{alias:'paid_partially', label: 'label_partially_paid'}],


            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        created() {

            this.getPageData();

            let self = this;
            this.columnsDefs = this.columnsDefs.filter((el) => {
                if (el.key == 'actions' && !self.$can('edit', 'agreement.tab_payments')) {
                    return false;
                }
                return true;
            });

            this.refreshDataTable();
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },

        methods: {

            getPageData() {
                this.async('get', '/select_options', {params:{options:['agreement_types']}}, function(res){
                    this.agreementTypes  =  res.data.agreement_types;
                });
            },

            getDetails(row) {
                let isClosed = !row.detailsShowed || row.detailsShowed === false;

                let self = this;

                if (isClosed) {
                    row.detailsShowed = true;

                } else {
                    row.detailsShowed = false;
                }
            },
            removePayRow(id) {
                this.confirmDeleting((result) => {

                    this.async('delete', '/' + this.MODULE_PREFIX + '_payments/api_removePayData/' + id, {params: {target: 'row'}}, function (resp) {
                        this.refreshDataTable();
                        // this.getCommonPaySumAndDate();
                    });

                })
            },
            removePayTable(id) {
                this.confirmDeleting((result) => {
                    this.async('delete', '/' + this.MODULE_PREFIX + '_payments/api_removePayData/' + id, {params: {target: 'table'}}, function (resp) {
                        this.refreshDataTable();
                        // this.getCommonPaySumAndDate();
                        this.updateRecivables();
                    });
                })
            },

            refreshDataTable: function () {
                this.isBusy = true;
                let url = '';

                let params =  {

                    length: this.tableData.perPage,
                    start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                    search: this.tableData.searchQuery,
                    sort_by: this.tableData.sortBy,
                    sort_desc: this.tableData.sortDesc? true : null,

                    agreement_type_id: this.filterData.agreement_type_id,
                    agreement_id: this.filterData.agreement_id,
                    contact_id: this.filterData.contact_id,
                    overdue: this.filterData.overdue,
                };

                for(let i = 0; i < this.filterData.pay_statuses.length; i++) {
                    params[this.filterData.pay_statuses[i]] = 1;
                }

                this.async('get', '/agreement_payments/api_get_due_law_rows', {
                    params: params
                }, function (resp) {
                    this.tableItems = resp.data.items.map((el) => {
                        el.detailsShowed = false;
                        return el;
                    });
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                    let self = this;

                });
            },
            updateRecivables(){

                this.async('get', '/agreement_' + this.PREFIX + 's/selects',
                    {
                        params: {
                            agreement_id:  this.isDeal? this.moduleItem.agreement_id : this.moduleItem.id,
                            payInfoType: 'default'
                        }
                    }, function (res) {
                        this.receivables = JSON.parse(res.data.tables);
                    });
            },

        },


    }
</script>

